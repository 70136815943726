<template>
  <a-col :span="24">
    <a-form-model-item required validateStatus="success" class="mb-0" prop="serveClasses" :rules="rules.serveClasses">
      <template v-slot:label>
        <a-space size="large">
          <span>服务班次</span>
          <a class="txt-btn" @click="addClasses" v-if="edit">添加</a>
        </a-space>
      </template>
      <div class="flex-box" v-for="(item, index) in serveClasses" :key="item.id || item.key">
        <a-form-model-item
          label=""
          :prop="`serveClasses[${index}].type`"
          :rules="rules.type"
          style="width: 180px;"
          class="mb-0"
        >
          <a-select placeholder="请选择类型" :options="classesTypeOptions" v-model="item.type" allowClear :disabled="disabled"> </a-select>
        </a-form-model-item>
        <div class="flex-box classe-time-box">
          <a-form-model-item label="" :prop="`serveClasses[${index}].start_time`" :rules="rules.start_time">
            <a-time-picker
              v-model="item.start_time"
              :format="timeFormat"
              :value-format="timeFormat"
              placeholder="选择开始时间"
              allowClear
              :disabled="disabled"
            />
          </a-form-model-item>
          <div class="interval-symbol">~</div>
          <a-form-model-item label="" :prop="`serveClasses[${index}].end_time`" :rules="rules.end_time">
            <a-time-picker
              v-model="item.end_time"
              :format="timeFormat"
              :value-format="timeFormat"
              placeholder="选择结束时间"
              allowClear
              :disabled="disabled"
            />
          </a-form-model-item>
        </div>
        <span class="txt-btn danger del-btn" @click="delClasses(index)" v-if="edit">删除</span>
      </div>
    </a-form-model-item>
  </a-col>
</template>

<script>
import { classesTypeOptions } from "../../../../common/hr/volunteer";
import { randomString } from "../../../../common/js/tool";

export default {
  name: "ServiceClasses",
  model: {
    prop: "serveClasses",
    event: "change",
  },
  props: {
    serveClasses: {
      type: Array,
      default: () => [],
    },
    edit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      timeFormat: "HH:mm",
      classesTypeOptions,
      list: [],
      rules: {
        serveClasses: [{ required: true, message: "请设置服务班次", trigger: "change" }],
        type: [{ required: true, message: "请选择类型", trigger: "change" }],
        start_time: [{ required: true, message: "请选择开始时间", trigger: "change" }],
        end_time: [{ required: true, message: "请选择结束时间", trigger: "change" }],
      },
    };
  },
  computed: {
    disabled() {
      return !this.edit;
    },
  },
  methods: {
    addClasses() {
      let item = {
        key: randomString(16),
      };
      this.serveClasses.push(item);
    },
    delClasses(index) {
      this.serveClasses.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.mb-0 {
  margin-bottom: 0;
}
.classe-time-box {
  margin-left: 12px;
}
.del-btn {
  margin-left: 32px;
  line-height: 32px;
}
.interval-symbol {
  padding-bottom: 8px;
  margin: 0 12px 24px;
  line-height: 32px;
}
</style>
