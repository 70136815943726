<template>
  <div>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :layout="layout"
      :label-align="labelAlign"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-row :gutter="gutter">
        <a-col :span="24">
          <div class="common-title">服务基本信息</div>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="服务类型" prop="service_type_id">
            <a-select
              placeholder="选择服务类型"
              :options="typeOptions"
              v-model="form.service_type_id"
              allowClear
              show-search
              option-filter-prop="children"
            >
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="服务地点" required validateStatus="success" class="mb-0">
            <a-row :gutter="gutter / 3">
              <a-col :span="9">
                <a-form-model-item label="" prop="location" class="special-item">
                  <a-cascader :options="city" placeholder="请选择所在地区" v-model="form.location" allowClear />
                </a-form-model-item>
              </a-col>
              <a-col :span="15">
                <a-form-model-item label="" prop="address" class="special-item">
                  <a-input
                    v-model="form.address"
                    placeholder="请输入服务地点详细地址（最多30字）"
                    :maxLength="30"
                    allowClear
                  ></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="招募总人数" prop="num">
            <a-input-number
              v-model="form.num"
              placeholder="请输入数字"
              :min="1"
              :step="1"
              allowClear
              style="width: 100%;"
            ></a-input-number>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="值班小组" prop="volunteer_group_id">
            <a-select
              placeholder="选择值班小组"
              :options="groupOptions"
              v-model="form.volunteer_group_id"
              allowClear
              show-search
              option-filter-prop="children"
            >
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="值班组长" prop="group_leader">
            <role-select
              v-model="form.group_leader"
              :props="{ placeholder: '请选择值班组长', allowClear: true }"
              :options="groupLeaderOptions"
              @focus="validateGroup"
            ></role-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="固定服务日" required validateStatus="success">
            <a-select placeholder="选择服务日" :options="weekOptions" v-model="form.week" allowClear disabled>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="服务日期" prop="service_date">
            <a-date-picker
              v-model="form.service_date"
              :format="format"
              :value-format="format"
              placeholder="选择时间"
              allowClear
              :disabledDate="disabledDate"
              @focus="validateGroup"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="可打卡时间" required validateStatus="success" class="mb-0">
            <div class="flex-box">
              <a-form-model-item label="" prop="start_time">
                <a-time-picker
                  v-model="form.start_time"
                  :format="timeFormat"
                  :value-format="timeFormat"
                  placeholder="选择开始时间"
                  allowClear
                />
              </a-form-model-item>
              <div class="interval-symbol">~</div>
              <a-form-model-item label="" prop="end_time">
                <a-time-picker
                  v-model="form.end_time"
                  :format="timeFormat"
                  :value-format="timeFormat"
                  placeholder="选择结束时间"
                  allowClear
                />
              </a-form-model-item>
            </div>
          </a-form-model-item>
        </a-col>
        <ServiceClasses v-model="form.serveClasses" :edit="form.edit_status != 2"></ServiceClasses>
        <ServicePost v-model="form.servePost" :edit="form.edit_status != 2"></ServicePost>
        <a-col :span="24">
          <a-form-model-item label="服务介绍" prop="info" :label-col="halfLabelCol" :wrapper-col="halfWrapperCol">
            <rich-editor v-model="form.info" placeholder="输入服务介绍"></rich-editor>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <div class="common-title">核销人管理</div>
        </a-col>
        <a-col :span="24">
          <a-form-model-item label="" :label-col="halfLabelCol" :wrapper-col="halfWrapperCol">
            <VerifierManage v-model="form.userRole" :privilege="3"></VerifierManage>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <div class="common-title">报名限制条件</div>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="可进行报名的年龄范围">
            <a-space>
              <a-input-number
                v-model="form.min_age"
                placeholder="请输入最小年龄（岁）"
                :min="1"
                :step="1"
                allowClear
              ></a-input-number>
              <span>~</span>
              <a-input-number
                v-model="form.max_age"
                placeholder="请输入最大年龄（岁）"
                :min="1"
                :step="1"
                allowClear
              ></a-input-number>
            </a-space>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="可进行报名的志工状态（多选）">
            <a-select
              placeholder="请选择"
              mode="multiple"
              :options="volunStatusOptions"
              v-model="form.volunteer_status"
              allowClear
            >
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="仅已选中的小组可参与（多选）">
            <a-select
              placeholder="选择小组"
              mode="multiple"
              :options="groupOptions"
              v-model="form.group_ids"
              allowClear
              show-search
              option-filter-prop="children"
            >
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="每个小组最多报名人数">
            <a-input-number
              v-model="form.enrollment"
              placeholder="请输入数字"
              :min="1"
              :step="1"
              allowClear
            ></a-input-number>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import {
  clone,
  dataConvertOptions,
  isEmpty,
  joinListKey,
  randomString,
  stringConvertTimestamp,
  timestampConvertString,
} from "../../../../common/js/tool";
import { volunStatusOptions, weekOptions } from "../../../../common/hr/volunteer";
import VerifierManage from "./VerifierManage.vue";
import city from "../../../../common/constant/city";
import ServiceClasses from "./ServiceClasses.vue";
import ServicePost from "./ServicePost.vue";

const multipleKeys = [{ key: "location" }, { key: "volunteer_status", type: Number }];

export default {
  name: "ServiceForm",
  components: {
    VerifierManage,
    ServiceClasses,
    ServicePost,
  },
  props: {
    initial: Object,
    halfLabelCol: Object,
    halfWrapperCol: Object,
    labelCol: Object,
    wrapperCol: Object,
    labelAlign: {
      type: String,
      default: "left",
    },
    layout: {
      type: String,
      default: "vertical",
    },
    gutter: {
      type: [Number, String, Array],
      default: 32,
    },
  },
  data() {
    return {
      format: "YYYY-MM-DD",
      timeFormat: "HH:mm",
      form: {},
      rules: {
        service_type_id: [{ required: true, message: "请选择服务类型", trigger: "change" }],
        location: [{ required: true, message: "请选择所在地区", trigger: "change" }],
        address: [{ required: true, message: "请输入服务地点详细地址", trigger: "blur" }],
        num: [{ required: true, message: "请输入招募总人数", trigger: "blur" }],
        volunteer_group_id: [{ required: true, message: "请选择值班小组", trigger: "change" }],
        group_leader: [{ required: true, message: "请选择值班组长", trigger: "change" }],
        service_date: [{ required: true, message: "请选择服务日期", trigger: "change" }],
        start_time: [{ required: true, message: "请选择打卡开始时间", trigger: "change" }],
        end_time: [{ required: true, message: "请选择打卡结束时间", trigger: "change" }],
        info: [{ required: true, message: "请输入服务介绍", trigger: "blur" }],
      },
      city,
      typeOptions: [],
      groupOptions: [],
      groupLeaderOptions: [],
      volunStatusOptions,
      groupPromise: null,
    };
  },
  computed: {
    weekOptions() {
      let options = clone(weekOptions);
      if (!this.form.week) {
        options.push({ lable: "无", value: "无" });
      }
      return options;
    },
  },
  watch: {
    initial: {
      handler() {
        this.init();
      },
      immediate: true,
    },
    "form.volunteer_group_id"() {
      this.groupChange();
    },
  },
  created() {
    this.getType();
    this.groupPromise = this.getGroup();
  },
  methods: {
    disabledDate(current) {
      let week = this.form.week;
      let volunteer_group_id = this.form.volunteer_group_id;
      if (current.valueOf() < Date.now() - 24 * 60 * 60 * 1000) {
        // 今天之前都不能选
        return true;
      }
      if (week && week != "无") {
        // 存在固定服务日
        let target = weekOptions.find((item) => item.value == week);
        return !(current.day() == target.week);
      } else if (!volunteer_group_id) {
        // 不存在小组
        return true;
      }
      return false;
    },
    getType() {
      this.$axios({
        url: "/admin/volunteer-service-type",
        method: "GET",
        params: {
          sort: "-id",
          pageSize: 9999,
        },
      }).then((res) => {
        this.typeOptions = dataConvertOptions(res.data);
      });
    },
    init() {
      let initial = this.initial;
      if (initial) {
        let form = clone(initial);
        // 服务日期
        if (form.service_date) {
          form.service_date = timestampConvertString(form.service_date, "yyyy-MM-dd");
        }
        // 可报名小组
        form.group_ids = form.joinGroup?.map((item) => item.volunteer_group_id) || [];
        if (form.joinGroup?.length > 0) {
          // 默认取第一个报名人数
          form.enrollment = form.joinGroup[0].enrollment;
        }
        multipleKeys.forEach(({ key, type = String }) => {
          if (!isEmpty(form[key])) {
            form[key] = form[key].split(",").map(type);
          } else {
            form[key] = [];
          }
        });
        this.form = form;
      }
    },
    // 获取小组列表
    getGroup() {
      return this.$axios({
        url: "/admin/volunteer-group",
        method: "GET",
        params: {
          sort: "sort,-id",
          pageSize: 9999,
          expand: "dutyTeam,chargehandLeader",
        },
      }).then((res) => {
        this.groupOptions = dataConvertOptions(res.data);
      });
    },
    timeChange(value, dateString) {
      if (dateString.length > 0) {
        this.$set(this.form, "start_time", stringConvertTimestamp(dateString[0]));
        this.$set(this.form, "end_time", stringConvertTimestamp(dateString[1]));
      } else {
        this.$set(this.form, "start_time", undefined);
        this.$set(this.form, "end_time", undefined);
      }
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate((flag) => {
          if (flag) {
            let form = clone(this.form);
            multipleKeys.forEach(({ key }) => {
              if (!isEmpty(form[key])) {
                form[key] = form[key].join(",");
              }
            });
            // 核销人
            if (form.userRole) {
              form.user_role = joinListKey(form.userRole);
            }
            if (form.service_date) {
              form.service_date = stringConvertTimestamp(form.service_date);
            }
            // 班次
            form.service_classes = this.getConnectData(form.serveClasses, this.initial?.serveClasses);
            // 岗位
            form.service_post = this.getConnectData(form.servePost, this.initial?.servePost);
            // 仅已选中的小组可参与
            if (form.group_ids) {
              let oldGroup = clone(this.initial?.joinGroup || []);
              let join_group = {
                add: [],
                update: [],
                delete: [],
              };
              form.group_ids?.forEach((id) => {
                let index = oldGroup.findIndex((item) => item.volunteer_group_id == id);
                let enrollment = form.enrollment;
                if (index > -1) {
                  // 存在则为编辑
                  let target = oldGroup.splice(index, 1)[0];
                  join_group.update.push({ ...target, enrollment }); // 覆盖报名人数
                } else {
                  // 不存在为新增
                  join_group.add.push({ volunteer_group_id: id, enrollment });
                }
              });
              join_group.delete = oldGroup; // 剩下的为删除
              form.join_group = JSON.stringify(join_group);
            }
            resolve(form);
          } else {
            reject();
          }
        });
      });
    },
    getConnectData(list, oldList) {
      let oldListCopy = clone(oldList || []);
      let connectObj = {
        add: [],
        update: [],
        delete: [],
      };
      if (list) {
        list?.forEach((item) => {
          if (item.id) {
            connectObj.update.push(item);
            // 去除当前仍存在的，剩下的为删除的
            let index = oldListCopy.findIndex((old) => old.id == item.id);
            oldListCopy.splice(index, 1);
          } else if (item.key) {
            connectObj.add.push(item);
          }
        });
      }
      connectObj.delete = oldListCopy.filter((item) => item.id); // 过滤初始化时默认的数据
      return JSON.stringify(connectObj);
    },
    confirm(formData) {
      this.form = formData;
      this.visible = false;
    },
    // 添加小组
    addGroup() {
      let item = {
        key: randomString(16),
      };
      let joinGroup = this.form.joinGroup;
      if (joinGroup) {
        joinGroup.push(item);
      } else {
        joinGroup = [item];
      }
      this.$set(this.form, "joinGroup", joinGroup);
    },
    // 删除小组
    delGroup(index) {
      this.form.joinGroup.splice(index, 1);
    },
    arrRemoveRepeat(list, key = "id") {
      let arr = [];
      list.forEach((item) => {
        if (!arr.some((val) => val[key] == item[key])) {
          arr.push(item);
        }
      });
      return arr;
    },
    groupChange() {
      let group_id = this.form.volunteer_group_id;
      if (group_id) {
        this.groupPromise.then(() => {
          let target = this.groupOptions.find((item) => item.id == group_id);
          let leaderOptions = [];
          // 组长，值班组长，副组长
          ["group_leader", "duty_team_leader", "chargehand"].forEach((key) => {
            if (target[key]) {
              let ids = target[key].split(",");
              ids.forEach((id) => {
                if (!leaderOptions.some((option) => option.value == id)) {
                  leaderOptions.push({ value: id });
                }
              });
            }
          });
          this.groupLeaderOptions = leaderOptions;
          if (target.is_fixed == 1) {
            // 固定服务日
            this.$set(this.form, "week", target.week);
          } else {
            this.$set(this.form, "week", "无");
          }
        });
      } else {
        this.groupLeaderOptions = [];
        this.$set(this.form, "week", "无");
      }
    },
    validateGroup() {
      if (!this.form.volunteer_group_id) {
        this.$message.warning("请先选择值班小组");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.ant-radio-group {
  min-height: 32px;
  line-height: 32px;
}
.input-item {
  display: inline-block;
  margin-bottom: 0;
  padding-bottom: 0;
}
.special-item {
  display: inline-block;
  width: 100%;
  padding: 0;
}
.mb-0 {
  margin-bottom: 0;
}
.ant-input-number {
  width: 240px;
}
.common-title {
  font-size: 20px;
  margin-bottom: 12px;
  font-weight: 600;
}
.select-item {
  display: inline-block;
  ::v-deep &.limit-item {
    margin-left: 32px;
    .ant-form-item-label {
      line-height: 32px;
    }
  }
  .ant-select {
    width: 240px;
  }
}
.del-btn {
  margin-left: 64px;
  line-height: 32px;
}
.interval-symbol {
  padding-bottom: 8px;
  margin: 0 12px 24px;
  line-height: 32px;
}
</style>
