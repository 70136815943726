<template>
  <a-col :span="24">
    <a-form-model-item>
      <template v-slot:label>
        <a-space size="large">
          <span>招募岗位</span>
          <a class="txt-btn" @click="addPost" v-if="edit">添加岗位</a>
        </a-space>
      </template>
      <div class="post-box" v-for="(item, index) in servePost" :key="item.id || item.key">
        <a-space size="large">
          <span>岗位{{ index + 1 }}</span>
          <span class="txt-btn danger del-btn" @click="delPost(index)" v-if="edit">删除</span>
        </a-space>
        <div class="post-form">
          <a-form-model-item label="岗位名称" :prop="`servePost[${index}].name`" :rules="rules.name">
            <a-input v-model="item.name" placeholder="请输入岗位名称" allowClear :disabled="disabled"></a-input>
          </a-form-model-item>
          <a-form-model-item label="岗位地点" :prop="`servePost[${index}].location`" :rules="rules.location">
            <a-input v-model="item.location" placeholder="请输入岗位地点" allowClear :disabled="disabled"></a-input>
          </a-form-model-item>
          <a-form-model-item label="岗位要求" :prop="`servePost[${index}].info`" :rules="rules.info">
            <a-textarea
              v-model="item.info"
              placeholder="请输入岗位要求（最多200字）"
              :maxLength="200"
              allowClear
              :disabled="disabled"
            ></a-textarea>
          </a-form-model-item>
          <a-form-model-item label="是否限制岗位人数" required validateStatus="success" class="mb-0">
            <a-form-model-item label="" :prop="`servePost[${index}].is_limit`" :rules="rules.is_limit" class="input-item">
              <a-radio-group v-model="item.is_limit" :disabled="disabled">
                <a-radio :value="item.value" v-for="item in numberOptions" :key="item.value">
                  {{ item.label }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="" :prop="`servePost[${index}].num`" :rules="rules.num" class="input-item" v-if="item.is_limit == 2">
              <a-input-number
                v-model="item.num"
                placeholder="请输入数字"
                :min="1"
                :step="1"
                allowClear
                :disabled="disabled"
              ></a-input-number>
            </a-form-model-item>
          </a-form-model-item>
        </div>
      </div>
    </a-form-model-item>
  </a-col>
</template>

<script>
import { numberOptions } from "../../../../common/hr/volunteer";
import { randomString } from "../../../../common/js/tool";

export default {
  name: "ServicePost",
  model: {
    prop: "servePost",
    event: "change",
  },
  props: {
    servePost: {
      type: Array,
      default: () => [],
    },
    edit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      timeFormat: "HH:mm",
      list: [],
      rules: {
        name: [{ required: true, message: "请输入岗位名称", trigger: "blur" }],
        location: [{ required: true, message: "请输入岗位地点", trigger: "blur" }],
        info: [{ required: true, message: "请输入岗位要求", trigger: "blur" }],
        is_limit: [{ required: true, message: "请选择是否限制岗位人数", trigger: "change" }],
        num: [{ required: true, message: "请输入限制人数", trigger: "blur" }],
      },
      numberOptions,
    };
  },
  computed: {
    disabled() {
      return !this.edit;
    },
  },
  methods: {
    addPost() {
      let item = {
        key: randomString(16),
      };
      this.servePost.push(item);
    },
    delPost(index) {
      this.servePost.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.mb-0 {
  margin-bottom: 0;
}
.post-box:not(:last-of-type) {
  margin-bottom: 12px;
}
.post-form {
  padding: 12px;
  border: 1px solid rgb(212, 202, 202);
  margin-top: 12px;
}
.input-item {
  display: inline-block;
}
.ant-radio-group {
  min-height: 40px;
  line-height: 32px;
}
.ant-input-number {
  width: 240px;
}
</style>
